// import * as React from "react"
import React from 'react';
import GiveContentTrans from "../components/GiveContentTrans";
import '../styles/main.scss';
import SEO from '../components/seo';

import Layout from '../components/Layout';


function CookiesPage() {
  return (
    <Layout>
    <main>

<GiveContentTrans language="et">
        <SEO
          title="Privaatsuspoliitika"
          description="Meie veebisaiti kasutades nõustute, et kogume ja kasutame Teie
          andmeid vastavalt käesolevale privaatsuspoliitikale."
          image="/pagecover.jpg"
        />
      </GiveContentTrans>
      <GiveContentTrans language="en">
        <SEO
          title="Privacy Policy"
   
          description="By using our website, you agree that we collect and use your information in accordance with this privacy policy."
          image="/pagecover-en-default.jpg"
        />
      </GiveContentTrans>

          <GiveContentTrans language="et">
 

      <section className="cookies-section">
        <div className="body-copy">
          <h1>Privaatsuspoliitika</h1>
          <p>
            <span className="name">Give Oü </span>jaoks on oluline Teie
            privaatsus ja järgida kõiki kehtivaid seadusi ja määrusi mis tahes
            isikuandmete kohta, mida võime Teie kohta koguda, kui külastate meie
            veebilehte{' '}
            <a className="webpage" href="https://give.ee">
              give.ee
            </a>
            . Meie veebisaiti kasutades nõustute, et kogume ja kasutame Teie
            andmeid vastavalt käesolevale privaatsuspoliitikale.
          </p>
          <p>
            Käesoleva privaatsuspoliitikaga soovime Teile selgitada, milliseid
            andmeid me kogume, kuidas neid kasutame ja millised on Teie õigused
            oma andmete kaitsmiseks. Poliis rakendub ainult meie veebilehele ja
            selle külastajatele ning ei rakendu andmetele, mida kogutakse muude
            kanalite kaudu.
          </p>
          <p>
            Teie andmete töötleja on <span className="name">Give Oü </span>
          </p>

          <p>
            Kogume ja töötleme teavet kooskõlas Euroopa Liidu üldise andmekaitse
            määrusega (GDPR), mis jõustus 25.05.2018.
          </p>
          <h2>Andmete kogumine ja kasutamine</h2>
          <h3>Milliseid andmeid me kogume</h3>
          <p>
            Kogutav teave hõlmab nii teavet, mida Te meile teadlikult ja
            aktiivselt edastate meie teenuste või kampaaniate kasutamisel või
            nendes osalemisel, kui ka kogu teavet, mille Teie seadmed saadavad
            meie toodetele ja teenustele juurde pääsemisel.
          </p>
          <p>
            Meie poolt kogutavad andmed sisaldavad endas teavet, mida olete meie
            teenuste või kampaaniate kasutamisel meile teadlikult jaganud ning
            teavet, mida Teie seadmed meile automaatselt edastavad.
          </p>
          <p>Teie isiklikud andmed võivad jõuda meieni järgmistel viisidel:</p>
          <ul>
            <li>
              Kui kontakteerute meiega telefoni, e-posti või online vestluse
              kaudu
            </li>

            <li>
              Kui sirvite veebilehte{' '}
              <a className="webpage" href="https://give.ee">
                give.ee
              </a>
            </li>

            <li>
              Kui Te kommenteerite, laigite või jagate mõnda postitust meie
              sotsiaalmeedia kontol
            </li>
            <li>Kui liitute meie uudiskirjaga</li>
            <li>Kui kommenteerite meie veebilehel olevaid postitusi</li>
          </ul>
          <h3>Kogutavad andmetüübid</h3>
          <h4>Isikuandmed</h4>
          <p>
            Küsime Teilt isiklikku informatsiooni ainult siis, kui see on
            vajalik Teile teenuse osutamiseks. Võttes meiega ühendust või
            külastades meie veebilehte, on võimalik, et jagate meiega järgnevat
            teavet:
          </p>
          <ul id="list">
            <li>Ees- ja perekonnanimi</li>
            <li>E-posti aadress</li>
            <li>Telefoninumber</li>
            <li>Teie ja meie vaheline vestlusajalugu</li>
            <li>Informatsioon Teie veebibrauseri ja IP-aadressi kohta</li>
          </ul>
          <h4>Logifailid</h4>
          <p>
            Meie veebileht{' '}
            <a className="webpage" href="https://give.ee">
              give.ee
            </a>{' '}
            salvestab oma külastajate kohta logifaile. Kogutava teabe hulka võib
            kuuluda:
          </p>
          <ul>
            <li>Kasutatava seadme tüüp</li>
            <li>Interneti-protokolli (IP) aadress</li>
            <li>brauseri tüüp</li>
            <li>Internetiteenuse pakkuja (ISP)</li>
            <li>Veebilehe külastamise kuupäev ja kellaaeg</li>
            <li>
              Sisenemis- ja väljumisleht veebilehel tehtavate klikkide arv
            </li>
          </ul>
          <p>
            Logifailides olevat informatsiooni kasutatakse trendide
            analüüsimiseks, veebilehe administreerimiseks, lehe külastajate
            tegevuste jälgimiseks ning demograafilise info kogumiseks. Saadud
            teabe ei ole mõeldud, ega võimaldagi isikut identifitseerida.
          </p>
          <h4>Küpsised</h4>
          <p>
            Nagu enamus veebilehti, kasutab ka{' '}
            <a className="webpage" href="https://give.ee">
              give.ee
            </a>{' '}
            "küpsiseid". Küpsis on sisuliselt tekstifail, mis salvestatakse
            veebisaidi külastamisel kasutaja seadmesse. Küpsiste abil määratakse
            ja salvestatakse eelistused, regionaalsed sätted, teenuste
            kasutamise valikud jpm.
          </p>
          <p>
            Soovi korral võite brauseri seadistuses ära keelata küpsiste
            kasutamise, kuid sel juhul on võimalik, et Teile pakutav teenus on
            häiritud.
          </p>
          <p>
            Küpsised jaotuvad püsi- ja sessiooniküpsisteks. Püsiküpsised jäävad
            Teie personaalarvutisse või mobiilseadmesse ka peale seda, kui olete
            sulgenud brauseri või arvuti. Sessiooniküpsised kustutatakse aga
            kohe pärast brauseri sulgemist.
          </p>
          <p>Küpsised jagunevad oma eesmärgi järgi:</p>
          <p>
            <b>Vajalikud küpsised.</b> Sessiooniküpsised, mille eesmärgiks on
            võimaldada Teile meie veebisaidil pakutavate teenuste kasutamist.
            Need küpsised aitavad kasutajaid autentida ja vältida
            kasutajakontode pahatahtlikku kasutamist. Ilma nende küpsisteta ei
            saa me Teile soovitud teenuseid pakkuda.
          </p>
          <p>
            <b>Teavitusküpsised.</b> Need küpsised tuvastavad, kas kasutajad on
            veebisaidil küpsiste kasutamist aktsepteerinud.
          </p>
          <p>
            <b>Funktsionaalsuse küpsised.</b> Need küpsised võimaldavad Teie
            brauseril meeles pidada valikuid, mida teete veebisaidi kasutamisel,
            näiteks Teie sisselogimisandmete või keele-eelistuste salvestamine.
            Nende küpsiste eesmärk on pakkuda Teile personaalsemat kogemust ja
            vältida eelistuste uuesti sisestamist.
          </p>
          <h3>Kuidas me andmeid kasutame</h3>

       
          <p>
            Kogutud informatsiooni võime kasutada erinevatel eesmärkidel,
            sealhulgas:
          </p>
          <ul>
            <li>
              Meie veebilehe hooldamiseks, haldamiseks, arendamiseks,
              personaliseerimiseks ja laiendamiseks
            </li>
            <li>
              Statistika kogumiseks, et saaksime pakkuda paremaid teenuseid
              ja/või pakkumisi ning mõista ja analüüsida meie veebilehe
              külastajate käitumist
            </li>
            <li>Teie tuvastamiseks telefoni, sõnumi või e-posti teel</li>
            <li>
              Teile informatsiooni saatmisel ja meie teenuste kohta tagasiside
              palumisel
            </li>
            <li>Pettuste vältimiseks ja meie veebilehe kaitsmiseks</li>
            <li>
              Võimaldamaks meil Teiega ühenduses olla sotsiaalmeedia konto kaudu
            </li>
            <li>
              Vestlusajaloo lugemise võimaldamiseks, et kiirendada asjakohase
              nõu andmist
            </li>
            <li>
              Eesmärgiga parandada meie teenuse kvaliteeti ja pakkuda tõhusamaid
              sirvimislahendusi
            </li>
            <li>
              Et arendada uusi uusi tooteid, teenuseid või parandada nende
              funktsionaalsust
            </li>

            <li>
              Meievaheliste interaktsioonide lihtsustamiseks ja kiirendamiseks,
              kui võtate meiega ühendust
            </li>
            <li>
              Muudel eesmärkidel. Näiteks andmete analüüsimiseks, trendide
              uurimiseks, meie reklaamikampaaniate tõhususe hindamiseks.
            </li>
          </ul>
          <h3>Millistel juhtudel on võimalik, et jagame Teie andmeid</h3>
          <ul>
            <li>
              Võime jagada või edastada Teie isikuandmeid seoses meie ettevõtte
              omanike vahetuse, ühinemise, varade müügi, finantseerimise või
              omandamise käigus. Teavitame Teid Teie isikuandmete edastamisest
              ja sellest, kui neile hakkavad kehtima mõne teise
              privaatsuspoliitika sätted.
            </li>
            <li>
              Võime jagada Teie andmeid oma partnerettevõtetega, nõudes neilt
              käesoleva privaatsuspoliitika järgimist. Partnerettevõtete hulka
              kuuluvad meie ema- ja tütarettevõtted, ühisettevõtte partnerid või
              ettevõtted, mis kuuluvad meile.
            </li>
            <li>
              Võime jagada Teie andmeid oma äripartneritega, et pakkuda Teile
              teatud tooteid, teenuseid või kampaaniaid.
            </li>
            <li>
              Võime Teie nõusolekul avaldada Teie isikuandmeid mis tahes muul
              eesmärgil.
            </li>
            <li>
              Oleme kohustatud Teie isikuandmed avaldama, kui seda nõuab seadus.
              Heas usus võime avaldada ka Teie andmeid, et järgida seadusest
              tulenevat kohustust, kaitsta meie õigusi või omandit, ennetada või
              tuvastada võimalikke meie teenuse kasutamisega seonduvaid
              õigusrikkumisi ja tagada teenuse kasutajate turvalisus.
            </li>
          </ul>
          <h3>Teie isikuandmete säilitamine</h3>
         
          <p>
            Ettevõte säilitab Teie isikuandmeid ainult seni, kuni see on vajalik
            käesolevas privaatsuspoliitikas sätestatud eesmärkidel. Säilitame ja
            kasutame Teie isikuandmeid ulatuses, mis on vajalik meie
            juriidiliste kohustuste täitmiseks (näiteks kui peame Teie andmeid
            säilitama kehtivate seaduste järgimiseks), vaidluste lahendamiseks
            ning meie juriidiliste lepingute ja eeskirjade jõustamiseks.
          </p>
          <p>
            Ettevõte säilitab logifaile ka siseanalüüsi eesmärgil. Logifaile
            säilitatakse tavaliselt lühemat aega, välja arvatud juhul, kui neid
            andmeid kasutatakse turvalisuse tugevdamiseks või meie teenuse
            funktsionaalsuse parandamiseks või kui me oleme seadusega kohustatud
            neid andmeid pikemaks ajaks säilitama.
          </p>
          <h3>Laste andmete töötlemine</h3>
          <p>
            Ükski meie toode või teenus ei ole suunatud alla 13-aastastele
            lastele ja me ei kogu teadlikult alla 13-aastaste laste andmeid.
            Kahtluse korral, et töötleme alla 13-aastase isiku andmeid,
            eemaldame selle isiku kõigist meie andmebaasidest.
          </p>
          <h2>Teie õigused oma andmete kaitsmiseks</h2>
          <p>
            Teil on alati õigus oma andmeid meiega mitte jagada, teadmisega, et
            see võib mõjutada Teie kogemust meie veebisaidil. Kui otsustate oma
            andmeid meiega mitte jagada, ei kohtle me Teid seepärast halvemini.
            Kui edastate meile isiklikku teavet, mõistate, et kogume, hoiame,
            kasutame ja avaldame seda vastavalt käesolevale
            privaatsuspoliitikale. Teil on õigus nõuda Teie kohta kogutavate
            andmete üksikasju.
          </p>
          <p>
            Saades Teie kohta isiklikku teavet kolmandalt osapoolelt, kaitseme
            seda vastavalt käesolevale privaatsuspoliitikale ja Eesti Vabariigi
            seadusele. Kui jagate ise kolmanda osapoole andmeid, kinnitate, et
            Teil on selleks ka õigus ja luba.
          </p>
          <p>
            Isegi kui olete varem andnud nõusoleku meiega oma isiklikke andmeid
            turunduse eesmärkidel jagada, jääb Teile õigus igal ajal oma meelt
            muuta. Teil on alati õigus loobuda meiega suhtlemisest ja nõuda, et
            eemaldaksime Teie andmed meie andmebaasidest. Aeg-ajalt võime ka
            küsida Teie kohta teavet Teie isiku kinnitamiseks. Kui leiate, et
            andmed, mida Teie kohta kogunud oleme, on ebatäpsed, aegunud,
            puudulikud, ebaolulised või eksitavad, palun võtke meiega ühendust
            e-posti teel: <span className="email">studio@give.ee</span>
            <span className="phone" />. Teeme kõik meist oleneva, et parandada
            ebatäpset, puudulikku, eksitavat või aegunud teavet.
          </p>
          
          <p>
            Kui arvate, et oleme rikkunud asjakohast andmekaitseseadust ja
            soovite esitada kaebuse, palun võtke meiega ühendust, kasutades
            käesolevast dokumendist leitavaid kontaktandmeid ja edastage meile
            kõik rikkumise kohta käivad üksikasjad. Uurime Teie kaebust
            viivitamata ja vastame teile kirjalikult, esitades uurimise
            tulemused ja toome välja sammud, mida Teie kaebuse lahendamiseks
            ette võtame. Samuti on Teil õigus pöörduda reguleeriva asutuse või
            andmekaitseasutuse poole.
          </p>
          <h2>GDPR andmekaitseõigused</h2>
          <p>
            Soovime olla veendunud, et olete teadlik kõigist oma
            andmekaitseõigustest. Igal lehe kasutajal on õigus järgmistele
            tingimustele:
          </p>
          <ul>
            <li>
              Juurdepääsuõigus - teil on õigus nõuda oma isikuandmete koopiaid.
            </li>
            <li>
              Õigus andmete parandustele - teil on õigus nõuda, et parandaksime
              Teie arvates ebatäpsed andmed. Samuti on teil õigus nõuda, et
              täiendaksime andmeid, mis on Teie arvates puudulikud.
            </li>
            <li>
              Kustutamisõigus - teatud tingimustel on teil õigus nõuda Teie
              isikuandmete kustutamist.
            </li>
            <li>
              Andmete töötlemise piiramise õigus - teatud tingimustel on teil
              õigus nõuda Teie isikuandmete kasutamise piiramist.
            </li>
            <li>
              Õigus vaidlustada isikuandmete kasutamine - teatud tingimustel on
              teil õigus vaidlustada Teie isikuandmete kasutamine.
            </li>
            <li>
              Õigus andmete teisaldatavusele - teatud tingimustel on teil õigus
              nõuda, et me edastaksime kogutud andmed kolmandale osapoolele või
              otse teile.
            </li>
          </ul>
          <h2>Teie andmete turvalisus</h2>
          <p>
            Teie isikuandmete turvalisus on meie jaoks oluline, kuid pidage
            meeles, et ükski Interneti-teel info vahetamise viis ega
            elektrooniline salvestusmeetod pole 100% turvaline. Kuigi anname
            endast kõik, et kaitsta Teie andmeid, ei saa me tagada nende
            täielikku turvalisust.
          </p>
          <p>
            <span className="name">Give Oü </span>võtab ette kõik mõistlikult
            vajalikud meetmed, et tagada Teie andmete töötlemine turvaliselt ja
            kooskõlas käesoleva privaatsuspoliitika ning Eesti Vabariigi
            seadusega. Teie andmeid ei edastata ühelegi organisatsioonile ega
            riigile, välja arvatud juhul, kui selleks on mõjuv põhjus ja on
            tagatud Teie andmete turvalisus.
          </p>
          <h2>Viitamine teistele veebisaitidele</h2>
          <p>
            Meie veebisaidilt võite leida linke teistele veebilehtedele, mis ei
            kuulu meile. Kui vajutate kolmanda osapoole lingil, suunatakse teid
            selle kolmanda osapoole saidile. Soovitame tungivalt tutvuda iga
            külastatava saidi privaatsuseeskirjaga.
          </p>
          <p>
            Me ei oma mingit kontrolli kolmandate osapoolte saitide üle ega võta
            vastutust nende sisu, privaatsuspoliitika, pakutavate teenuste või
            muu eest.
          </p>
          <h2>Privaatsuspoliitika uuendused</h2>
          <p>
            Vajadusel on meil õigus teha käesolevale privaatsuspoliitika
            dokumendile muudatusi. Privaatsuspoliitika uuenemise järel muudame
            me selle allosas paiknevat dokumendi värskendamise kuupäeva. Kui
            külastate ja kasutate meie veebilehte ka pärast privaatsuspoliitika
            uuendamist, käsitleme me seda kui Teie vaikivat nõusolekut
            muudatustega.
          </p>
          <p>
            Viimati muudetud <span className="kuupaev">02/01/2023</span>
          </p>
          <p className="reference">
            See dokument on koostatud{' '}
            <a href="https://easyweb.ee/privaatsuspoliitika-generaator/">
              EasyWeb privaatsuspoliitika generaatoriga
            </a>
          </p>
        </div>
      </section>

      </GiveContentTrans>
      <GiveContentTrans language="en">
      <section className="cookies-section">
        <div className="body-copy">
      <h1>Privacy Policy</h1>
          <p>
            <span className="name">Give Oü</span> values your privacy and adheres to all applicable laws and regulations regarding any personal data we may collect about you when you visit our website <a className="webpage" href="https://give.ee">give.ee</a>. By using our website, you agree that we collect and use your information in accordance with this privacy policy.
          </p>
          <p>
            With this privacy policy, we aim to explain to you what data we collect, how we use it, and what your rights are to protect your data. This policy applies only to our website and its visitors, and does not apply to data collected through other channels.
          </p>
          <p>
            Your data processor is <span className="name">Give Oü</span>.
          </p>
          <p>
            We collect and process information in accordance with the General Data Protection Regulation (GDPR) of the European Union, which came into effect on May 25, 2018.
          </p>
          <h2>Data Collection and Use</h2>
          <h3>What Data We Collect</h3>
          <p>
            The information we collect includes both the information you actively and knowingly provide us when using or participating in our services or campaigns, as well as all the information your devices send to our products and services when accessing them.
          </p>
          <p>
            The data we collect includes information that you have knowingly shared with us when using our services or campaigns, as well as information that your devices automatically transmit to us.
          </p>
          <p>Your personal data may reach us in the following ways:</p>
          <ul>
            <li>
              When you contact us by phone, email, or online chat
            </li>
            <li>
              When you browse the website <a className="webpage" href="https://give.ee">give.ee</a>
            </li>
            <li>
              When you comment, like, or share a post on our social media account
            </li>
            <li>
              When you sign up for our newsletter
            </li>
            <li>
              When you comment on posts on our website
            </li>
          </ul>
          <h3>Types of Data We Collect</h3>
          <h4>Personal Data</h4>
          <p>
            We only ask for your personal information when it is necessary to provide you with our services. When contacting us or visiting our website, you may share the following information with us:
          </p>
          <ul id="list">
            <li>First and last name</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Your conversation history with us</li>
            <li>Information about your web browser and IP address</li>
          </ul>
          <h4>Log Files</h4>
          <p>
            Our website{' '}
            <a className="webpage" href="https://give.ee">
              give.ee
            </a>{' '}
            stores log files about our visitors. The collected information may
            include:
          </p>
          <ul>
            <li>Type of device used</li>
            <li>Internet Protocol (IP) address</li>
            <li>Browser type</li>
            <li>Internet Service Provider (ISP)</li>
            <li>Date and time of website visit</li>
            <li>Number of clicks on entry and exit pages on the website</li>
          </ul>
          <p>
            The information in log files is used for trend analysis, website
            administration, monitoring visitor activities, and collecting
            demographic information. The information obtained is not intended
            and does not allow for identification of individuals.
          </p>
          <h4>Cookies</h4>
          <p>
            Like most websites,{' '}
            <a className="webpage" href="https://give.ee">
              give.ee
            </a>{' '}
            uses "cookies." A cookie is essentially a text file that is stored
            on a user's device when they visit a website. Cookies are used to
            determine and store preferences, regional settings, service usage
            choices, and more.
          </p>
          <p>
            If desired, you can disable the use of cookies in your browser
            settings, but in this case, the service offered to you may be
            disrupted.
          </p>
          <p>
            Cookies are divided into persistent and session cookies. Persistent
            cookies remain on your personal computer or mobile device even
            after you have closed your browser or computer. Session cookies, on
            the other hand, are deleted immediately after the browser is closed.
          </p>
          <p>Cookies are classified according to their purpose:</p>
          <p>
            <b>Essential cookies.</b> Session cookies that allow you to use the
            services offered on our website. These cookies help authenticate
            users and prevent malicious use of user accounts. We cannot provide
            you with the desired services without these cookies.
          </p>
          <p>
            <b>Notification cookies.</b> These cookies identify whether users
            have accepted the use of cookies on the website.
          </p>
          <p>
            <b>Functionality cookies.</b> These cookies allow your browser to
            remember choices you make when using the website, such as saving
            your login details or language preferences. The purpose of these
            cookies is to provide you with a more personalized experience and
            to avoid re-entering preferences.
          </p>
          <h3>How we use data</h3>
          <p>
We can use the collected information for various purposes, including:
</p>
<ul>
  <li>
    Maintaining, managing, developing, personalizing, and expanding our website
  </li>
  <li>
    Collecting statistics to provide better services and/or offers, and to understand and analyze the behavior of visitors to our website
  </li>
  <li>Identifying you by phone, message, or email</li>
  <li>
    Sending you information and requesting feedback about our services
  </li>
  <li>Preventing fraud and protecting our website</li>
  <li>
    Allowing us to contact you through social media accounts
  </li>
  <li>
    Allowing for the reading of chat history to accelerate relevant advice
  </li>
  <li>
    With the aim of improving the quality of our service and providing more efficient browsing solutions
  </li>
  <li>
    Developing new products or services or improving their functionality
  </li>
  <li>
    Simplifying and accelerating our interactions when you contact us
  </li>
  <li>
    For other purposes, such as data analysis, trend research, and evaluating the effectiveness of our advertising campaigns.
  </li>
</ul>
<h3>When can we share your data?</h3>
<ul>
  <li>
    We may share or transfer your personal data in connection with a change in ownership of our company, merger, sale of assets, financing, or acquisition. We will inform you about the transfer of your personal data and when different privacy policy provisions will apply to them.
  </li>
  <li>
    We may share your data with our partner companies, requiring them to comply with this privacy policy. Our partner companies include our parent and subsidiary companies, joint venture partners, or companies that we own.
  </li>
  <li>
    We may share your data with our business partners to provide you with certain products, services, or promotions.
  </li>
  <li>
    We may disclose your personal data for any other purpose with your consent.
  </li>
  <li>
    We are required to disclose your personal data if required by law. In good faith, we may also disclose your data to comply with legal obligations, protect our rights or property, prevent or detect possible service-related infringements, and ensure the safety of service users.
  </li>
</ul>
<h3>Retaining your personal data</h3>
<p>
The company will retain your personal data only for as long as necessary for the purposes set out in this privacy policy. We will retain and use your personal data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
</p>
<p>
The company also keeps log files for internal analysis purposes. Log files are usually kept for a shorter period of time, except when this data is used to strengthen security or improve the functionality of our service or when we are required by law to keep such data for longer periods of time.
</p>
<h3>Processing children's data</h3>
<p>
None of our products or services are intended for children under the age of 13, and we do not knowingly collect data from children under the age of 13. In case we process personal data of a person under the age of 13, we will remove that person from all our databases.
</p>
<h2>Your rights to protect your data</h2>
<p>
You always have the right not to share your data with us, with the knowledge that this may affect your experience on our website. If you choose not to share your data with us, we will not treat you any worse for it. If you provide us with personal information, you understand that we collect, store, use and disclose it in accordance with this privacy policy. You have the right to request details of the data collected about you.
</p>
<p>
When we receive personal information about you from a third party, we protect it in accordance with this privacy policy and Estonian law. If you share data from a third party yourself, you confirm that you have the right and permission to do so.
</p>
<p>
Even if you have previously given consent to share your personal data with us for marketing purposes, you have the right to change your mind at any time. You always have the right to opt-out of communication with us and request that we remove your data from our databases. From time to time, we may also ask for information about you to verify your identity. If you believe that the data we have collected about you is inaccurate, outdated, incomplete, irrelevant, or misleading, please contact us by email at <span className="email">studio@give.ee</span>
<span className="phone" />. We will do everything we can to correct inaccurate, incomplete, misleading or outdated information.
</p>
<p>
        If you think we have violated any relevant data protection laws and
        you wish to make a complaint, please contact us using the contact details
        found in this document and provide us with all relevant details of the breach.
        We will investigate your complaint without delay and respond to you in writing,
        setting out the results of our investigation and the steps we will take to resolve
        your complaint. You also have the right to lodge a complaint with the regulatory
        authority or data protection authority.
      </p>
      <h2>GDPR Data Protection Rights</h2>
      <p>
        We want to ensure that you are aware of all your data protection rights. Every
        user of the website is entitled to the following conditions:
      </p>
      <ul>
        <li>
          Right of access - you have the right to request copies of your personal data.
        </li>
        <li>
          Right to rectification - you have the right to request that we correct any
          information you believe is inaccurate. You also have the right to request that
          we complete the information you believe is incomplete.
        </li>
        <li>
          Right to erasure - under certain conditions, you have the right to request that
          we erase your personal data.
        </li>
        <li>
          Right to restriction of processing - under certain conditions, you have the right
          to request that we restrict the use of your personal data.
        </li>
        <li>
          Right to object to processing - under certain conditions, you have the right to
          object to the processing of your personal data.
        </li>
        <li>
          Right to data portability - under certain conditions, you have the right to
          request that we transfer the data we have collected to a third party or directly to you.
        </li>
      </ul>
      <h2>Your Data Security</h2>
      <p>
        Your personal data security is important to us, but please note that no method of
        transmitting information over the Internet or storing electronic data is 100%
        secure. Although we do everything in our power to protect your data, we cannot
        guarantee its complete security.
      </p>
      <p>
        <span className="name">Give Oü </span>takes all reasonably necessary measures
        to ensure the secure processing of your data in accordance with this Privacy Policy
        and the laws of the Republic of Estonia. Your data will not be transmitted to any
        organization or country except in cases where there is a compelling reason and the
        security of your data is ensured.
      </p>
      <h2>Links to Other Websites</h2>
      <p>
        Our website may contain links to other websites that are not owned by us. If you
        click on a third-party link, you will be directed to that third party's site. We
        strongly advise you to review the privacy policy of every site you visit.
      </p>
      <p>
        We have no control over, and assume no responsibility for, the content, privacy
        policies, or practices of any third-party sites or services.
      </p>
      <h2>Privacy Policy Updates</h2>
      <p>
        We reserve the right to update this privacy policy from time to time. We will notify
        you of any changes by posting the new privacy policy on this page. We encourage you
        to review this privacy policy periodically for any changes. This privacy policy was
        last updated on the date indicated above.
      </p>
      <p>
  Last modified <span className="kuupaev">02/01/2023</span>
</p>
<p className="reference">
  This document has been created using 
  <a href="https://easyweb.ee/privaatsuspoliitika-generaator/">
    EasyWeb privacy policy generator and translated to English
  </a>
</p>

          </div>
          </section>
      </GiveContentTrans>
    </main>
    </Layout>
  );
}

export default CookiesPage;
